import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaGrav, FaHeart } from "react-icons/fa"

const IndexPage = () => (
    <Layout>

        <SEO title="Home" />

        <div className="flex flex-wrap md:m-8 justify-center">

            <div className="bg-custom-gray border border-black rounded-md p-4 text-primary m-4">
                <div className="flex items-center justify-center text-center">
                    <FaGrav className="text-4xl mr-2 text-green-500 " /> based in<br />Leipzig, Germany
                </div>
            </div>
            <div className="bg-custom-gray border border-black rounded-md p-4 text-primary my-4 md:m-4">
                <div className="text-center">   
                    <p>Currently diving into</p>
                </div>
                <div className="flex flex-wrap justify-center">
                    <div className="mr-1 mt-1 bg-sky-400 rounded-md p-1 text-custom-gray text-xs">Flutter</div>
                    <div className="mr-1 mt-1 bg-orange-400 rounded-md p-1 text-custom-gray text-xs">Jupyter</div>
                    <div className="mt-1 bg-lime-400 rounded-md p-1 text-custom-gray text-xs">DevOps</div>
                </div>
            </div>
            <div className="bg-custom-gray box-border border border-black rounded-md text-primary p-4 m-4">
                <div className="text-center">
                    Solving backend issues (sometimes frontend's too) with 
                </div>
                <div className="flex flex-wrap justify-center">
                    <div className="bg-blue-700 rounded-md p-1 text-primary mr-1 mt-1 text-xs">Python</div>
                    <div className="bg-green-900 rounded-md p-1 text-primary mr-1 mt-1 text-xs">Django</div>
                    <div className="bg-yellow-300 rounded-md p-1 text-custom-gray mr-1 mt-1 text-xs">JavaScript</div>
                    <div className="bg-stone-300 rounded-md p-1 text-custom-gray mr-1 mt-1 text-xs">PostgreSQL</div>
                    <div className="bg-indigo-600 rounded-md p-1 text-primary mr-1 mt-1 text-xs">Testing</div>
                    <div className="bg-pink-600 rounded-md p-1 text-primary mt-1 text-xs">Microservices</div>
                    
                </div>
            </div>
            <div className="bg-custom-gray border border-black rounded-md p-4 text-primary m-4 text-center flex">
                <div className="flex justify-center items-center">Occasionally taking freelance projects.<br />Giving back by volunteering.</div>
            </div>
            <div className="bg-custom-gray border border-black rounded-md p-4 text-primary m-4 text-center flex">
                <div className="flex justify-center items-center">Committed to building bridges<br /> between tech and non-tech worlds.<br />Curious about digital transformation.</div>
            </div>
            <div className="bg-custom-gray border border-black rounded-md p-4 text-primary m-4 flex">
                <div className="flex flex-wrap m-2 justify-center items-center">
                    <div className="border-custom-white border rounded-md p-1 text-primary m-1 text-xs">Tech exp. 7+</div>
                    <div className="border-custom-white border rounded-md p-1 text-primary m-1 text-xs">B.Sc. CS</div>
                    <div className="border-custom-white border rounded-md p-1 text-primary m-1 text-xs">M.A. Sociology</div>
                </div>
            </div>
            <div className="bg-custom-gray border border-black rounded-md p-4 text-primary m-4 text-center">
                <p>Favorite side-project atm</p>
                <a href="https://modernism-in-architecture.org" target="_blank" rel="noreferrer"><span className="hover:text-primary-link bg-gray-600 rounded-md">modernism-in-architecture.org</span></a>
                <div className="flex flex-wrap m-2 justify-center">
                    <div className="bg-green-900 rounded-md p-1 text-primary mr-1 text-xs">Django</div>
                    <div className="bg-yellow-300 rounded-md p-1 text-custom-gray mr-1 text-xs">JavaScript</div>
                    <div className="bg-sky-400 rounded-md p-1 text-custom-gray text-xs">Flutter</div>
                </div>
            </div>
            <div className="bg-custom-gray border border-black rounded-md p-4 text-primary m-4">
                <div className="flex items-center justify-center text-center">
                    <FaHeart className="text-4xl md:text-2xl mr-2 text-red-500" />implementing ideas, community, psychology, yoga, mountains, cats, coffee
                </div>
            </div>

        </div>

    </Layout >
)

export default IndexPage
